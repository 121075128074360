@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
    padding: 0;
    margin: 0;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

/* Set the color of the placeholder text to light gray */
.public-DraftEditorPlaceholder-root {
    color: #b3b3b3;
    position: absolute;
    z-index: 0;
}

.DraftEditorPlaceholder-hidden {
    display: none;
}
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  }
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  }
.container {
    width: 100%}
@media (min-width: 640px) {
    .container {
        max-width: 640px}}
@media (min-width: 768px) {
    .container {
        max-width: 768px}}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px}}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px}}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px}}
#__next :is(.visible) {
    visibility: visible}
#__next :is(.static) {
    position: static}
#__next :is(.fixed) {
    position: fixed}
#__next :is(.absolute) {
    position: absolute}
#__next :is(.relative) {
    position: relative}
#__next :is(.sticky) {
    position: sticky}
#__next :is(.bottom-0) {
    bottom: 0px}
#__next :is(.bottom-0\.5) {
    bottom: 0.125rem}
#__next :is(.bottom-1) {
    bottom: 0.25rem}
#__next :is(.bottom-1\.5) {
    bottom: 0.375rem}
#__next :is(.bottom-2) {
    bottom: 0.5rem}
#__next :is(.bottom-2\.5) {
    bottom: 0.625rem}
#__next :is(.bottom-3) {
    bottom: 0.75rem}
#__next :is(.bottom-3\.5) {
    bottom: 0.875rem}
#__next :is(.bottom-4) {
    bottom: 1rem}
#__next :is(.bottom-6) {
    bottom: 1.5rem}
#__next :is(.bottom-8) {
    bottom: 2rem}
#__next :is(.bottom-px) {
    bottom: 1px}
#__next :is(.left-0) {
    left: 0px}
#__next :is(.left-0\.5) {
    left: 0.125rem}
#__next :is(.left-1) {
    left: 0.25rem}
#__next :is(.left-1\.5) {
    left: 0.375rem}
#__next :is(.left-1\/2) {
    left: 50%}
#__next :is(.left-2) {
    left: 0.5rem}
#__next :is(.left-2\.5) {
    left: 0.625rem}
#__next :is(.left-3) {
    left: 0.75rem}
#__next :is(.left-3\.5) {
    left: 0.875rem}
#__next :is(.left-4) {
    left: 1rem}
#__next :is(.left-6) {
    left: 1.5rem}
#__next :is(.left-8) {
    left: 2rem}
#__next :is(.left-px) {
    left: 1px}
#__next :is(.right-0) {
    right: 0px}
#__next :is(.right-0\.5) {
    right: 0.125rem}
#__next :is(.right-1) {
    right: 0.25rem}
#__next :is(.right-1\.5) {
    right: 0.375rem}
#__next :is(.right-1\/2) {
    right: 50%}
#__next :is(.right-2) {
    right: 0.5rem}
#__next :is(.right-2\.5) {
    right: 0.625rem}
#__next :is(.right-3) {
    right: 0.75rem}
#__next :is(.right-3\.5) {
    right: 0.875rem}
#__next :is(.right-4) {
    right: 1rem}
#__next :is(.right-6) {
    right: 1.5rem}
#__next :is(.right-8) {
    right: 2rem}
#__next :is(.right-px) {
    right: 1px}
#__next :is(.top-0) {
    top: 0px}
#__next :is(.top-0\.5) {
    top: 0.125rem}
#__next :is(.top-1) {
    top: 0.25rem}
#__next :is(.top-1\.5) {
    top: 0.375rem}
#__next :is(.top-2) {
    top: 0.5rem}
#__next :is(.top-2\.5) {
    top: 0.625rem}
#__next :is(.top-3) {
    top: 0.75rem}
#__next :is(.top-3\.5) {
    top: 0.875rem}
#__next :is(.top-4) {
    top: 1rem}
#__next :is(.top-6) {
    top: 1.5rem}
#__next :is(.top-8) {
    top: 2rem}
#__next :is(.top-px) {
    top: 1px}
#__next :is(.z-10) {
    z-index: 10}
#__next :is(.z-20) {
    z-index: 20}
#__next :is(.col-span-1) {
    grid-column: span 1 / span 1}
#__next :is(.col-span-10) {
    grid-column: span 10 / span 10}
#__next :is(.col-span-11) {
    grid-column: span 11 / span 11}
#__next :is(.col-span-12) {
    grid-column: span 12 / span 12}
#__next :is(.col-span-2) {
    grid-column: span 2 / span 2}
#__next :is(.col-span-3) {
    grid-column: span 3 / span 3}
#__next :is(.col-span-4) {
    grid-column: span 4 / span 4}
#__next :is(.col-span-5) {
    grid-column: span 5 / span 5}
#__next :is(.col-span-6) {
    grid-column: span 6 / span 6}
#__next :is(.col-span-7) {
    grid-column: span 7 / span 7}
#__next :is(.col-span-8) {
    grid-column: span 8 / span 8}
#__next :is(.col-span-9) {
    grid-column: span 9 / span 9}
#__next :is(.col-start-2) {
    grid-column-start: 2}
#__next :is(.col-start-3) {
    grid-column-start: 3}
#__next :is(.col-start-4) {
    grid-column-start: 4}
#__next :is(.col-start-5) {
    grid-column-start: 5}
#__next :is(.col-start-6) {
    grid-column-start: 6}
#__next :is(.col-start-7) {
    grid-column-start: 7}
#__next :is(.mx-auto) {
    margin-left: auto;
    margin-right: auto}
#__next :is(.-mb-0) {
    margin-bottom: -0px}
#__next :is(.-mb-0\.5) {
    margin-bottom: -0.125rem}
#__next :is(.-mb-1) {
    margin-bottom: -0.25rem}
#__next :is(.-mb-1\.5) {
    margin-bottom: -0.375rem}
#__next :is(.-mb-2) {
    margin-bottom: -0.5rem}
#__next :is(.-mb-2\.5) {
    margin-bottom: -0.625rem}
#__next :is(.-mb-3) {
    margin-bottom: -0.75rem}
#__next :is(.-mb-3\.5) {
    margin-bottom: -0.875rem}
#__next :is(.-mb-4) {
    margin-bottom: -1rem}
#__next :is(.-mb-6) {
    margin-bottom: -1.5rem}
#__next :is(.-mb-8) {
    margin-bottom: -2rem}
#__next :is(.-mb-px) {
    margin-bottom: -1px}
#__next :is(.-ml-0) {
    margin-left: -0px}
#__next :is(.-ml-0\.5) {
    margin-left: -0.125rem}
#__next :is(.-ml-1) {
    margin-left: -0.25rem}
#__next :is(.-ml-1\.5) {
    margin-left: -0.375rem}
#__next :is(.-ml-2) {
    margin-left: -0.5rem}
#__next :is(.-ml-2\.5) {
    margin-left: -0.625rem}
#__next :is(.-ml-3) {
    margin-left: -0.75rem}
#__next :is(.-ml-3\.5) {
    margin-left: -0.875rem}
#__next :is(.-ml-4) {
    margin-left: -1rem}
#__next :is(.-ml-6) {
    margin-left: -1.5rem}
#__next :is(.-ml-8) {
    margin-left: -2rem}
#__next :is(.-ml-px) {
    margin-left: -1px}
#__next :is(.-mr-0) {
    margin-right: -0px}
#__next :is(.-mr-0\.5) {
    margin-right: -0.125rem}
#__next :is(.-mr-1) {
    margin-right: -0.25rem}
#__next :is(.-mr-1\.5) {
    margin-right: -0.375rem}
#__next :is(.-mr-2) {
    margin-right: -0.5rem}
#__next :is(.-mr-2\.5) {
    margin-right: -0.625rem}
#__next :is(.-mr-3) {
    margin-right: -0.75rem}
#__next :is(.-mr-3\.5) {
    margin-right: -0.875rem}
#__next :is(.-mr-4) {
    margin-right: -1rem}
#__next :is(.-mr-6) {
    margin-right: -1.5rem}
#__next :is(.-mr-8) {
    margin-right: -2rem}
#__next :is(.-mr-px) {
    margin-right: -1px}
#__next :is(.-mt-0) {
    margin-top: -0px}
#__next :is(.-mt-0\.5) {
    margin-top: -0.125rem}
#__next :is(.-mt-1) {
    margin-top: -0.25rem}
#__next :is(.-mt-1\.5) {
    margin-top: -0.375rem}
#__next :is(.-mt-2) {
    margin-top: -0.5rem}
#__next :is(.-mt-2\.5) {
    margin-top: -0.625rem}
#__next :is(.-mt-3) {
    margin-top: -0.75rem}
#__next :is(.-mt-3\.5) {
    margin-top: -0.875rem}
#__next :is(.-mt-4) {
    margin-top: -1rem}
#__next :is(.-mt-6) {
    margin-top: -1.5rem}
#__next :is(.-mt-8) {
    margin-top: -2rem}
#__next :is(.-mt-px) {
    margin-top: -1px}
#__next :is(.mb-0) {
    margin-bottom: 0px}
#__next :is(.mb-0\.5) {
    margin-bottom: 0.125rem}
#__next :is(.mb-1) {
    margin-bottom: 0.25rem}
#__next :is(.mb-1\.5) {
    margin-bottom: 0.375rem}
#__next :is(.mb-2) {
    margin-bottom: 0.5rem}
#__next :is(.mb-2\.5) {
    margin-bottom: 0.625rem}
#__next :is(.mb-3) {
    margin-bottom: 0.75rem}
#__next :is(.mb-3\.5) {
    margin-bottom: 0.875rem}
#__next :is(.mb-4) {
    margin-bottom: 1rem}
#__next :is(.mb-6) {
    margin-bottom: 1.5rem}
#__next :is(.mb-8) {
    margin-bottom: 2rem}
#__next :is(.mb-px) {
    margin-bottom: 1px}
#__next :is(.ml-0) {
    margin-left: 0px}
#__next :is(.ml-0\.5) {
    margin-left: 0.125rem}
#__next :is(.ml-1) {
    margin-left: 0.25rem}
#__next :is(.ml-1\.5) {
    margin-left: 0.375rem}
#__next :is(.ml-2) {
    margin-left: 0.5rem}
#__next :is(.ml-2\.5) {
    margin-left: 0.625rem}
#__next :is(.ml-3) {
    margin-left: 0.75rem}
#__next :is(.ml-3\.5) {
    margin-left: 0.875rem}
#__next :is(.ml-4) {
    margin-left: 1rem}
#__next :is(.ml-6) {
    margin-left: 1.5rem}
#__next :is(.ml-8) {
    margin-left: 2rem}
#__next :is(.ml-px) {
    margin-left: 1px}
#__next :is(.mr-0) {
    margin-right: 0px}
#__next :is(.mr-0\.5) {
    margin-right: 0.125rem}
#__next :is(.mr-1) {
    margin-right: 0.25rem}
#__next :is(.mr-1\.5) {
    margin-right: 0.375rem}
#__next :is(.mr-2) {
    margin-right: 0.5rem}
#__next :is(.mr-2\.5) {
    margin-right: 0.625rem}
#__next :is(.mr-3) {
    margin-right: 0.75rem}
#__next :is(.mr-3\.5) {
    margin-right: 0.875rem}
#__next :is(.mr-4) {
    margin-right: 1rem}
#__next :is(.mr-6) {
    margin-right: 1.5rem}
#__next :is(.mr-8) {
    margin-right: 2rem}
#__next :is(.mr-px) {
    margin-right: 1px}
#__next :is(.mt-0) {
    margin-top: 0px}
#__next :is(.mt-0\.5) {
    margin-top: 0.125rem}
#__next :is(.mt-1) {
    margin-top: 0.25rem}
#__next :is(.mt-1\.5) {
    margin-top: 0.375rem}
#__next :is(.mt-2) {
    margin-top: 0.5rem}
#__next :is(.mt-2\.5) {
    margin-top: 0.625rem}
#__next :is(.mt-3) {
    margin-top: 0.75rem}
#__next :is(.mt-3\.5) {
    margin-top: 0.875rem}
#__next :is(.mt-4) {
    margin-top: 1rem}
#__next :is(.mt-6) {
    margin-top: 1.5rem}
#__next :is(.mt-8) {
    margin-top: 2rem}
#__next :is(.mt-px) {
    margin-top: 1px}
#__next :is(.block) {
    display: block}
#__next :is(.inline-block) {
    display: inline-block}
#__next :is(.inline) {
    display: inline}
#__next :is(.flex) {
    display: flex}
#__next :is(.inline-flex) {
    display: inline-flex}
#__next :is(.table) {
    display: table}
#__next :is(.grid) {
    display: grid}
#__next :is(.contents) {
    display: contents}
#__next :is(.hidden) {
    display: none}
#__next :is(.h-0) {
    height: 0px}
#__next :is(.h-0\.5) {
    height: 0.125rem}
#__next :is(.h-1) {
    height: 0.25rem}
#__next :is(.h-10) {
    height: 2.5rem}
#__next :is(.h-2) {
    height: 0.5rem}
#__next :is(.h-3) {
    height: 0.75rem}
#__next :is(.h-4) {
    height: 1rem}
#__next :is(.h-44) {
    height: 11rem}
#__next :is(.h-5) {
    height: 1.25rem}
#__next :is(.h-6) {
    height: 1.5rem}
#__next :is(.h-7) {
    height: 1.75rem}
#__next :is(.h-80) {
    height: 20rem}
#__next :is(.h-9) {
    height: 2.25rem}
#__next :is(.h-full) {
    height: 100%}
#__next :is(.max-h-\[228px\]) {
    max-height: 228px}
#__next :is(.max-h-fit) {
    max-height: -moz-fit-content;
    max-height: fit-content}
#__next :is(.w-0) {
    width: 0px}
#__next :is(.w-0\.5) {
    width: 0.125rem}
#__next :is(.w-1) {
    width: 0.25rem}
#__next :is(.w-1\/2) {
    width: 50%}
#__next :is(.w-16) {
    width: 4rem}
#__next :is(.w-2) {
    width: 0.5rem}
#__next :is(.w-3) {
    width: 0.75rem}
#__next :is(.w-4) {
    width: 1rem}
#__next :is(.w-48) {
    width: 12rem}
#__next :is(.w-5) {
    width: 1.25rem}
#__next :is(.w-6) {
    width: 1.5rem}
#__next :is(.w-7) {
    width: 1.75rem}
#__next :is(.w-9) {
    width: 2.25rem}
#__next :is(.w-full) {
    width: 100%}
#__next :is(.w-max) {
    width: -moz-max-content;
    width: max-content}
#__next :is(.min-w-\[10rem\]) {
    min-width: 10rem}
#__next :is(.min-w-min) {
    min-width: -moz-min-content;
    min-width: min-content}
#__next :is(.max-w-full) {
    max-width: 100%}
#__next :is(.max-w-xs) {
    max-width: 20rem}
#__next :is(.flex-1) {
    flex: 1 1 0%}
#__next :is(.flex-none) {
    flex: none}
#__next :is(.flex-shrink-0) {
    flex-shrink: 0}
#__next :is(.shrink) {
    flex-shrink: 1}
#__next :is(.shrink-0) {
    flex-shrink: 0}
#__next :is(.-translate-x-1\/2) {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}
#__next :is(.translate-x-1\/2) {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}
#__next :is(.-rotate-180) {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}
#__next :is(.transform) {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}
@keyframes spin {
    to {
        transform: rotate(360deg)}}
#__next :is(.animate-spin) {
    animation: spin 1s linear infinite}
#__next :is(.cursor-default) {
    cursor: default}
#__next :is(.cursor-pointer) {
    cursor: pointer}
#__next :is(.cursor-text) {
    cursor: text}
#__next :is(.resize) {
    resize: both}
#__next :is(.grid-cols-1) {
    grid-template-columns: repeat(1, minmax(0, 1fr))}
#__next :is(.grid-cols-10) {
    grid-template-columns: repeat(10, minmax(0, 1fr))}
#__next :is(.grid-cols-11) {
    grid-template-columns: repeat(11, minmax(0, 1fr))}
#__next :is(.grid-cols-12) {
    grid-template-columns: repeat(12, minmax(0, 1fr))}
#__next :is(.grid-cols-2) {
    grid-template-columns: repeat(2, minmax(0, 1fr))}
#__next :is(.grid-cols-3) {
    grid-template-columns: repeat(3, minmax(0, 1fr))}
#__next :is(.grid-cols-4) {
    grid-template-columns: repeat(4, minmax(0, 1fr))}
#__next :is(.grid-cols-5) {
    grid-template-columns: repeat(5, minmax(0, 1fr))}
#__next :is(.grid-cols-6) {
    grid-template-columns: repeat(6, minmax(0, 1fr))}
#__next :is(.grid-cols-7) {
    grid-template-columns: repeat(7, minmax(0, 1fr))}
#__next :is(.grid-cols-8) {
    grid-template-columns: repeat(8, minmax(0, 1fr))}
#__next :is(.grid-cols-9) {
    grid-template-columns: repeat(9, minmax(0, 1fr))}
#__next :is(.grid-cols-none) {
    grid-template-columns: none}
#__next :is(.flex-row) {
    flex-direction: row}
#__next :is(.flex-row-reverse) {
    flex-direction: row-reverse}
#__next :is(.flex-col) {
    flex-direction: column}
#__next :is(.flex-col-reverse) {
    flex-direction: column-reverse}
#__next :is(.flex-wrap) {
    flex-wrap: wrap}
#__next :is(.flex-nowrap) {
    flex-wrap: nowrap}
#__next :is(.items-start) {
    align-items: flex-start}
#__next :is(.items-end) {
    align-items: flex-end}
#__next :is(.items-center) {
    align-items: center}
#__next :is(.items-baseline) {
    align-items: baseline}
#__next :is(.items-stretch) {
    align-items: stretch}
#__next :is(.justify-start) {
    justify-content: flex-start}
#__next :is(.justify-end) {
    justify-content: flex-end}
#__next :is(.justify-center) {
    justify-content: center}
#__next :is(.justify-between) {
    justify-content: space-between}
#__next :is(.justify-around) {
    justify-content: space-around}
#__next :is(.justify-evenly) {
    justify-content: space-evenly}
#__next :is(.space-x-0 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-0\.5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-1 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-1\.5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-2 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-2\.5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-3 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-3\.5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-4 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-6 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-8 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-x-px > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))}
#__next :is(.space-y-1 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))}
#__next :is(.divide-y > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))}
#__next :is(.divide-amber-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-divide-opacity))}
#__next :is(.divide-amber-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-divide-opacity))}
#__next :is(.divide-black > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-divide-opacity))}
#__next :is(.divide-blue-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-divide-opacity))}
#__next :is(.divide-cyan-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-divide-opacity))}
#__next :is(.divide-emerald-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-divide-opacity))}
#__next :is(.divide-fuchsia-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-divide-opacity))}
#__next :is(.divide-gray-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-divide-opacity))}
#__next :is(.divide-green-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-divide-opacity))}
#__next :is(.divide-green-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-divide-opacity))}
#__next :is(.divide-green-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-divide-opacity))}
#__next :is(.divide-green-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-divide-opacity))}
#__next :is(.divide-green-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-divide-opacity))}
#__next :is(.divide-green-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-divide-opacity))}
#__next :is(.divide-green-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-divide-opacity))}
#__next :is(.divide-green-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-divide-opacity))}
#__next :is(.divide-green-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-divide-opacity))}
#__next :is(.divide-green-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-divide-opacity))}
#__next :is(.divide-indigo-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-divide-opacity))}
#__next :is(.divide-lime-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-divide-opacity))}
#__next :is(.divide-neutral-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-divide-opacity))}
#__next :is(.divide-orange-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-divide-opacity))}
#__next :is(.divide-pink-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-divide-opacity))}
#__next :is(.divide-purple-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-divide-opacity))}
#__next :is(.divide-red-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-divide-opacity))}
#__next :is(.divide-red-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-divide-opacity))}
#__next :is(.divide-red-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-divide-opacity))}
#__next :is(.divide-red-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-divide-opacity))}
#__next :is(.divide-red-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-divide-opacity))}
#__next :is(.divide-red-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-divide-opacity))}
#__next :is(.divide-red-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-divide-opacity))}
#__next :is(.divide-red-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-divide-opacity))}
#__next :is(.divide-red-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-divide-opacity))}
#__next :is(.divide-red-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-divide-opacity))}
#__next :is(.divide-rose-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-divide-opacity))}
#__next :is(.divide-sky-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-divide-opacity))}
#__next :is(.divide-slate-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-divide-opacity))}
#__next :is(.divide-stone-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-divide-opacity))}
#__next :is(.divide-teal-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-divide-opacity))}
#__next :is(.divide-transparent > :not([hidden]) ~ :not([hidden])) {
    border-color: transparent}
#__next :is(.divide-violet-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-divide-opacity))}
#__next :is(.divide-violet-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-divide-opacity))}
#__next :is(.divide-white > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-divide-opacity))}
#__next :is(.divide-yellow-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-100 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-200 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-400 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-50 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-600 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-700 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-800 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-divide-opacity))}
#__next :is(.divide-zinc-900 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-divide-opacity))}
#__next :is(.overflow-auto) {
    overflow: auto}
#__next :is(.overflow-hidden) {
    overflow: hidden}
#__next :is(.overflow-x-auto) {
    overflow-x: auto}
#__next :is(.overflow-y-auto) {
    overflow-y: auto}
#__next :is(.overflow-x-clip) {
    overflow-x: clip}
#__next :is(.truncate) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap}
#__next :is(.whitespace-nowrap) {
    white-space: nowrap}
#__next :is(.rounded) {
    border-radius: 0.25rem}
#__next :is(.rounded-full) {
    border-radius: 9999px}
#__next :is(.rounded-lg) {
    border-radius: 0.5rem}
#__next :is(.rounded-md) {
    border-radius: 0.375rem}
#__next :is(.rounded-none) {
    border-radius: 0px}
#__next :is(.rounded-b) {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem}
#__next :is(.rounded-b-full) {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px}
#__next :is(.rounded-b-lg) {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem}
#__next :is(.rounded-b-md) {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem}
#__next :is(.rounded-b-none) {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px}
#__next :is(.rounded-l) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem}
#__next :is(.rounded-l-full) {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px}
#__next :is(.rounded-l-lg) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem}
#__next :is(.rounded-l-md) {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem}
#__next :is(.rounded-l-none) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px}
#__next :is(.rounded-r) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem}
#__next :is(.rounded-r-full) {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px}
#__next :is(.rounded-r-lg) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem}
#__next :is(.rounded-r-md) {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem}
#__next :is(.rounded-r-none) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px}
#__next :is(.rounded-t) {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem}
#__next :is(.rounded-t-full) {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px}
#__next :is(.rounded-t-lg) {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem}
#__next :is(.rounded-t-md) {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem}
#__next :is(.rounded-t-none) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px}
#__next :is(.border) {
    border-width: 1px}
#__next :is(.border-0) {
    border-width: 0px}
#__next :is(.border-2) {
    border-width: 2px}
#__next :is(.border-4) {
    border-width: 4px}
#__next :is(.border-b) {
    border-bottom-width: 1px}
#__next :is(.border-b-0) {
    border-bottom-width: 0px}
#__next :is(.border-b-2) {
    border-bottom-width: 2px}
#__next :is(.border-b-4) {
    border-bottom-width: 4px}
#__next :is(.border-l) {
    border-left-width: 1px}
#__next :is(.border-l-0) {
    border-left-width: 0px}
#__next :is(.border-l-2) {
    border-left-width: 2px}
#__next :is(.border-l-4) {
    border-left-width: 4px}
#__next :is(.border-r) {
    border-right-width: 1px}
#__next :is(.border-r-0) {
    border-right-width: 0px}
#__next :is(.border-r-2) {
    border-right-width: 2px}
#__next :is(.border-r-4) {
    border-right-width: 4px}
#__next :is(.border-t) {
    border-top-width: 1px}
#__next :is(.border-t-0) {
    border-top-width: 0px}
#__next :is(.border-t-2) {
    border-top-width: 2px}
#__next :is(.border-t-4) {
    border-top-width: 4px}
#__next :is(.border-amber-100) {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity))}
#__next :is(.border-amber-200) {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity))}
#__next :is(.border-amber-300) {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity))}
#__next :is(.border-amber-400) {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity))}
#__next :is(.border-amber-50) {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity))}
#__next :is(.border-amber-500) {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity))}
#__next :is(.border-amber-600) {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity))}
#__next :is(.border-amber-700) {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity))}
#__next :is(.border-amber-800) {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity))}
#__next :is(.border-amber-900) {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity))}
#__next :is(.border-black) {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity))}
#__next :is(.border-blue-100) {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity))}
#__next :is(.border-blue-200) {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity))}
#__next :is(.border-blue-300) {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity))}
#__next :is(.border-blue-400) {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity))}
#__next :is(.border-blue-50) {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity))}
#__next :is(.border-blue-500) {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity))}
#__next :is(.border-blue-600) {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity))}
#__next :is(.border-blue-700) {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity))}
#__next :is(.border-blue-800) {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity))}
#__next :is(.border-blue-900) {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity))}
#__next :is(.border-cyan-100) {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity))}
#__next :is(.border-cyan-200) {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity))}
#__next :is(.border-cyan-300) {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity))}
#__next :is(.border-cyan-400) {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity))}
#__next :is(.border-cyan-50) {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity))}
#__next :is(.border-cyan-500) {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity))}
#__next :is(.border-cyan-600) {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity))}
#__next :is(.border-cyan-700) {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity))}
#__next :is(.border-cyan-800) {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity))}
#__next :is(.border-cyan-900) {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity))}
#__next :is(.border-emerald-100) {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity))}
#__next :is(.border-emerald-200) {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity))}
#__next :is(.border-emerald-300) {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity))}
#__next :is(.border-emerald-400) {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity))}
#__next :is(.border-emerald-50) {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity))}
#__next :is(.border-emerald-500) {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity))}
#__next :is(.border-emerald-600) {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity))}
#__next :is(.border-emerald-700) {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity))}
#__next :is(.border-emerald-800) {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity))}
#__next :is(.border-emerald-900) {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-100) {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-200) {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-300) {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-400) {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-50) {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-500) {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-600) {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-700) {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-800) {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity))}
#__next :is(.border-fuchsia-900) {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity))}
#__next :is(.border-gray-100) {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity))}
#__next :is(.border-gray-200) {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity))}
#__next :is(.border-gray-300) {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))}
#__next :is(.border-gray-400) {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity))}
#__next :is(.border-gray-50) {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity))}
#__next :is(.border-gray-500) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity))}
#__next :is(.border-gray-600) {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity))}
#__next :is(.border-gray-700) {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity))}
#__next :is(.border-gray-800) {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity))}
#__next :is(.border-gray-900) {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity))}
#__next :is(.border-green-100) {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity))}
#__next :is(.border-green-200) {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity))}
#__next :is(.border-green-300) {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity))}
#__next :is(.border-green-400) {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity))}
#__next :is(.border-green-50) {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity))}
#__next :is(.border-green-500) {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity))}
#__next :is(.border-green-600) {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity))}
#__next :is(.border-green-700) {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity))}
#__next :is(.border-green-800) {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity))}
#__next :is(.border-green-900) {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity))}
#__next :is(.border-indigo-100) {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity))}
#__next :is(.border-indigo-200) {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity))}
#__next :is(.border-indigo-300) {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity))}
#__next :is(.border-indigo-400) {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity))}
#__next :is(.border-indigo-50) {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity))}
#__next :is(.border-indigo-500) {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity))}
#__next :is(.border-indigo-600) {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity))}
#__next :is(.border-indigo-700) {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity))}
#__next :is(.border-indigo-800) {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity))}
#__next :is(.border-indigo-900) {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity))}
#__next :is(.border-lime-100) {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity))}
#__next :is(.border-lime-200) {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity))}
#__next :is(.border-lime-300) {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity))}
#__next :is(.border-lime-400) {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity))}
#__next :is(.border-lime-50) {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity))}
#__next :is(.border-lime-500) {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity))}
#__next :is(.border-lime-600) {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity))}
#__next :is(.border-lime-700) {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity))}
#__next :is(.border-lime-800) {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity))}
#__next :is(.border-lime-900) {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity))}
#__next :is(.border-neutral-100) {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity))}
#__next :is(.border-neutral-200) {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity))}
#__next :is(.border-neutral-300) {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity))}
#__next :is(.border-neutral-400) {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity))}
#__next :is(.border-neutral-50) {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity))}
#__next :is(.border-neutral-500) {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity))}
#__next :is(.border-neutral-600) {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity))}
#__next :is(.border-neutral-700) {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity))}
#__next :is(.border-neutral-800) {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity))}
#__next :is(.border-neutral-900) {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity))}
#__next :is(.border-orange-100) {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity))}
#__next :is(.border-orange-200) {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity))}
#__next :is(.border-orange-300) {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity))}
#__next :is(.border-orange-400) {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity))}
#__next :is(.border-orange-50) {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity))}
#__next :is(.border-orange-500) {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity))}
#__next :is(.border-orange-600) {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity))}
#__next :is(.border-orange-700) {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity))}
#__next :is(.border-orange-800) {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity))}
#__next :is(.border-orange-900) {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity))}
#__next :is(.border-pink-100) {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity))}
#__next :is(.border-pink-200) {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity))}
#__next :is(.border-pink-300) {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity))}
#__next :is(.border-pink-400) {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity))}
#__next :is(.border-pink-50) {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity))}
#__next :is(.border-pink-500) {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity))}
#__next :is(.border-pink-600) {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity))}
#__next :is(.border-pink-700) {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity))}
#__next :is(.border-pink-900) {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity))}
#__next :is(.border-purple-100) {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity))}
#__next :is(.border-purple-200) {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity))}
#__next :is(.border-purple-300) {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity))}
#__next :is(.border-purple-400) {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity))}
#__next :is(.border-purple-50) {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity))}
#__next :is(.border-purple-500) {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity))}
#__next :is(.border-purple-600) {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity))}
#__next :is(.border-purple-700) {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity))}
#__next :is(.border-purple-800) {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity))}
#__next :is(.border-purple-900) {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity))}
#__next :is(.border-red-100) {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity))}
#__next :is(.border-red-200) {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity))}
#__next :is(.border-red-300) {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity))}
#__next :is(.border-red-400) {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity))}
#__next :is(.border-red-50) {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity))}
#__next :is(.border-red-500) {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity))}
#__next :is(.border-red-600) {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity))}
#__next :is(.border-red-700) {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity))}
#__next :is(.border-red-800) {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity))}
#__next :is(.border-red-900) {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity))}
#__next :is(.border-rose-100) {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity))}
#__next :is(.border-rose-200) {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity))}
#__next :is(.border-rose-300) {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity))}
#__next :is(.border-rose-400) {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity))}
#__next :is(.border-rose-50) {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity))}
#__next :is(.border-rose-500) {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity))}
#__next :is(.border-rose-600) {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity))}
#__next :is(.border-rose-700) {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity))}
#__next :is(.border-rose-800) {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity))}
#__next :is(.border-rose-900) {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity))}
#__next :is(.border-sky-100) {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity))}
#__next :is(.border-sky-200) {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity))}
#__next :is(.border-sky-300) {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity))}
#__next :is(.border-sky-400) {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity))}
#__next :is(.border-sky-50) {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity))}
#__next :is(.border-sky-500) {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity))}
#__next :is(.border-sky-600) {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity))}
#__next :is(.border-sky-700) {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity))}
#__next :is(.border-sky-800) {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity))}
#__next :is(.border-sky-900) {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity))}
#__next :is(.border-slate-100) {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity))}
#__next :is(.border-slate-200) {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity))}
#__next :is(.border-slate-300) {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity))}
#__next :is(.border-slate-400) {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity))}
#__next :is(.border-slate-50) {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity))}
#__next :is(.border-slate-500) {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity))}
#__next :is(.border-slate-600) {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity))}
#__next :is(.border-slate-700) {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity))}
#__next :is(.border-slate-800) {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity))}
#__next :is(.border-slate-900) {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity))}
#__next :is(.border-stone-100) {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity))}
#__next :is(.border-stone-200) {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity))}
#__next :is(.border-stone-300) {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity))}
#__next :is(.border-stone-400) {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity))}
#__next :is(.border-stone-50) {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity))}
#__next :is(.border-stone-500) {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity))}
#__next :is(.border-stone-600) {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity))}
#__next :is(.border-stone-700) {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity))}
#__next :is(.border-stone-800) {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity))}
#__next :is(.border-stone-900) {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity))}
#__next :is(.border-teal-100) {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity))}
#__next :is(.border-teal-200) {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity))}
#__next :is(.border-teal-300) {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity))}
#__next :is(.border-teal-400) {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity))}
#__next :is(.border-teal-50) {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity))}
#__next :is(.border-teal-500) {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity))}
#__next :is(.border-teal-600) {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity))}
#__next :is(.border-teal-700) {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity))}
#__next :is(.border-teal-800) {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity))}
#__next :is(.border-teal-900) {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity))}
#__next :is(.border-transparent) {
    border-color: transparent}
#__next :is(.border-violet-100) {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity))}
#__next :is(.border-violet-200) {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity))}
#__next :is(.border-violet-300) {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity))}
#__next :is(.border-violet-400) {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity))}
#__next :is(.border-violet-50) {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity))}
#__next :is(.border-violet-500) {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity))}
#__next :is(.border-violet-600) {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity))}
#__next :is(.border-violet-700) {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity))}
#__next :is(.border-violet-800) {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity))}
#__next :is(.border-violet-900) {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity))}
#__next :is(.border-white) {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))}
#__next :is(.border-yellow-100) {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity))}
#__next :is(.border-yellow-200) {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity))}
#__next :is(.border-yellow-300) {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity))}
#__next :is(.border-yellow-400) {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity))}
#__next :is(.border-yellow-50) {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity))}
#__next :is(.border-yellow-500) {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity))}
#__next :is(.border-yellow-600) {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity))}
#__next :is(.border-yellow-700) {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity))}
#__next :is(.border-yellow-800) {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity))}
#__next :is(.border-yellow-900) {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity))}
#__next :is(.border-zinc-100) {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity))}
#__next :is(.border-zinc-200) {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity))}
#__next :is(.border-zinc-300) {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity))}
#__next :is(.border-zinc-400) {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity))}
#__next :is(.border-zinc-50) {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity))}
#__next :is(.border-zinc-500) {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity))}
#__next :is(.border-zinc-600) {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity))}
#__next :is(.border-zinc-700) {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity))}
#__next :is(.border-zinc-800) {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity))}
#__next :is(.border-zinc-900) {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity))}
#__next :is(.bg-amber-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity))}
#__next :is(.bg-amber-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity))}
#__next :is(.bg-black) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity))}
#__next :is(.bg-blue-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity))}
#__next :is(.bg-cyan-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity))}
#__next :is(.bg-emerald-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity))}
#__next :is(.bg-fuchsia-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity))}
#__next :is(.bg-gray-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity))}
#__next :is(.bg-green-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity))}
#__next :is(.bg-green-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity))}
#__next :is(.bg-green-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity))}
#__next :is(.bg-green-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity))}
#__next :is(.bg-green-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity))}
#__next :is(.bg-green-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))}
#__next :is(.bg-green-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity))}
#__next :is(.bg-green-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity))}
#__next :is(.bg-green-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity))}
#__next :is(.bg-green-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity))}
#__next :is(.bg-indigo-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity))}
#__next :is(.bg-inherit) {
    background-color: inherit}
#__next :is(.bg-lime-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity))}
#__next :is(.bg-lime-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity))}
#__next :is(.bg-neutral-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity))}
#__next :is(.bg-orange-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity))}
#__next :is(.bg-pink-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity))}
#__next :is(.bg-purple-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity))}
#__next :is(.bg-red-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity))}
#__next :is(.bg-red-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity))}
#__next :is(.bg-red-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity))}
#__next :is(.bg-red-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity))}
#__next :is(.bg-red-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity))}
#__next :is(.bg-red-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity))}
#__next :is(.bg-red-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity))}
#__next :is(.bg-red-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity))}
#__next :is(.bg-red-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity))}
#__next :is(.bg-red-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity))}
#__next :is(.bg-rose-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity))}
#__next :is(.bg-sky-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))}
#__next :is(.bg-slate-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity))}
#__next :is(.bg-stone-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity))}
#__next :is(.bg-teal-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity))}
#__next :is(.bg-transparent) {
    background-color: transparent}
#__next :is(.bg-violet-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity))}
#__next :is(.bg-violet-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity))}
#__next :is(.bg-white) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity))}
#__next :is(.bg-yellow-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity))}
#__next :is(.bg-zinc-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity))}
#__next :is(.p-0) {
    padding: 0px}
#__next :is(.p-0\.5) {
    padding: 0.125rem}
#__next :is(.p-1) {
    padding: 0.25rem}
#__next :is(.p-1\.5) {
    padding: 0.375rem}
#__next :is(.p-2) {
    padding: 0.5rem}
#__next :is(.p-2\.5) {
    padding: 0.625rem}
#__next :is(.p-3) {
    padding: 0.75rem}
#__next :is(.p-3\.5) {
    padding: 0.875rem}
#__next :is(.p-4) {
    padding: 1rem}
#__next :is(.p-6) {
    padding: 1.5rem}
#__next :is(.p-8) {
    padding: 2rem}
#__next :is(.p-px) {
    padding: 1px}
#__next :is(.px-0) {
    padding-left: 0px;
    padding-right: 0px}
#__next :is(.px-0\.5) {
    padding-left: 0.125rem;
    padding-right: 0.125rem}
#__next :is(.px-1) {
    padding-left: 0.25rem;
    padding-right: 0.25rem}
#__next :is(.px-1\.5) {
    padding-left: 0.375rem;
    padding-right: 0.375rem}
#__next :is(.px-2) {
    padding-left: 0.5rem;
    padding-right: 0.5rem}
#__next :is(.px-2\.5) {
    padding-left: 0.625rem;
    padding-right: 0.625rem}
#__next :is(.px-3) {
    padding-left: 0.75rem;
    padding-right: 0.75rem}
#__next :is(.px-3\.5) {
    padding-left: 0.875rem;
    padding-right: 0.875rem}
#__next :is(.px-4) {
    padding-left: 1rem;
    padding-right: 1rem}
#__next :is(.px-6) {
    padding-left: 1.5rem;
    padding-right: 1.5rem}
#__next :is(.px-8) {
    padding-left: 2rem;
    padding-right: 2rem}
#__next :is(.px-px) {
    padding-left: 1px;
    padding-right: 1px}
#__next :is(.py-0) {
    padding-top: 0px;
    padding-bottom: 0px}
#__next :is(.py-0\.5) {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem}
#__next :is(.py-1) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem}
#__next :is(.py-1\.5) {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem}
#__next :is(.py-2) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem}
#__next :is(.py-2\.5) {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem}
#__next :is(.py-3) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem}
#__next :is(.py-3\.5) {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem}
#__next :is(.py-4) {
    padding-top: 1rem;
    padding-bottom: 1rem}
#__next :is(.py-6) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem}
#__next :is(.py-8) {
    padding-top: 2rem;
    padding-bottom: 2rem}
#__next :is(.py-px) {
    padding-top: 1px;
    padding-bottom: 1px}
#__next :is(.pb-0) {
    padding-bottom: 0px}
#__next :is(.pb-0\.5) {
    padding-bottom: 0.125rem}
#__next :is(.pb-1) {
    padding-bottom: 0.25rem}
#__next :is(.pb-1\.5) {
    padding-bottom: 0.375rem}
#__next :is(.pb-2) {
    padding-bottom: 0.5rem}
#__next :is(.pb-2\.5) {
    padding-bottom: 0.625rem}
#__next :is(.pb-3) {
    padding-bottom: 0.75rem}
#__next :is(.pb-3\.5) {
    padding-bottom: 0.875rem}
#__next :is(.pb-4) {
    padding-bottom: 1rem}
#__next :is(.pb-6) {
    padding-bottom: 1.5rem}
#__next :is(.pb-8) {
    padding-bottom: 2rem}
#__next :is(.pb-px) {
    padding-bottom: 1px}
#__next :is(.pl-0) {
    padding-left: 0px}
#__next :is(.pl-0\.5) {
    padding-left: 0.125rem}
#__next :is(.pl-1) {
    padding-left: 0.25rem}
#__next :is(.pl-1\.5) {
    padding-left: 0.375rem}
#__next :is(.pl-2) {
    padding-left: 0.5rem}
#__next :is(.pl-2\.5) {
    padding-left: 0.625rem}
#__next :is(.pl-3) {
    padding-left: 0.75rem}
#__next :is(.pl-3\.5) {
    padding-left: 0.875rem}
#__next :is(.pl-4) {
    padding-left: 1rem}
#__next :is(.pl-6) {
    padding-left: 1.5rem}
#__next :is(.pl-8) {
    padding-left: 2rem}
#__next :is(.pl-px) {
    padding-left: 1px}
#__next :is(.pr-0) {
    padding-right: 0px}
#__next :is(.pr-0\.5) {
    padding-right: 0.125rem}
#__next :is(.pr-1) {
    padding-right: 0.25rem}
#__next :is(.pr-1\.5) {
    padding-right: 0.375rem}
#__next :is(.pr-2) {
    padding-right: 0.5rem}
#__next :is(.pr-2\.5) {
    padding-right: 0.625rem}
#__next :is(.pr-3) {
    padding-right: 0.75rem}
#__next :is(.pr-3\.5) {
    padding-right: 0.875rem}
#__next :is(.pr-4) {
    padding-right: 1rem}
#__next :is(.pr-6) {
    padding-right: 1.5rem}
#__next :is(.pr-8) {
    padding-right: 2rem}
#__next :is(.pr-px) {
    padding-right: 1px}
#__next :is(.pt-0) {
    padding-top: 0px}
#__next :is(.pt-0\.5) {
    padding-top: 0.125rem}
#__next :is(.pt-1) {
    padding-top: 0.25rem}
#__next :is(.pt-1\.5) {
    padding-top: 0.375rem}
#__next :is(.pt-2) {
    padding-top: 0.5rem}
#__next :is(.pt-2\.5) {
    padding-top: 0.625rem}
#__next :is(.pt-3) {
    padding-top: 0.75rem}
#__next :is(.pt-3\.5) {
    padding-top: 0.875rem}
#__next :is(.pt-4) {
    padding-top: 1rem}
#__next :is(.pt-6) {
    padding-top: 1.5rem}
#__next :is(.pt-8) {
    padding-top: 2rem}
#__next :is(.pt-px) {
    padding-top: 1px}
#__next :is(.text-left) {
    text-align: left}
#__next :is(.text-center) {
    text-align: center}
#__next :is(.text-right) {
    text-align: right}
#__next :is(.align-top) {
    vertical-align: top}
#__next :is(.align-middle) {
    vertical-align: middle}
#__next :is(.text-3xl) {
    font-size: 1.875rem;
    line-height: 2.25rem}
#__next :is(.text-base) {
    font-size: 1rem;
    line-height: 1.5rem}
#__next :is(.text-lg) {
    font-size: 1.125rem;
    line-height: 1.75rem}
#__next :is(.text-sm) {
    font-size: 0.875rem;
    line-height: 1.25rem}
#__next :is(.text-xl) {
    font-size: 1.25rem;
    line-height: 1.75rem}
#__next :is(.text-xs) {
    font-size: 0.75rem;
    line-height: 1rem}
#__next :is(.font-medium) {
    font-weight: 500}
#__next :is(.font-normal) {
    font-weight: 400}
#__next :is(.font-semibold) {
    font-weight: 600}
#__next :is(.lowercase) {
    text-transform: lowercase}
#__next :is(.capitalize) {
    text-transform: capitalize}
#__next :is(.italic) {
    font-style: italic}
#__next :is(.tabular-nums) {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)}
#__next :is(.text-amber-100) {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity))}
#__next :is(.text-amber-200) {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity))}
#__next :is(.text-amber-300) {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity))}
#__next :is(.text-amber-400) {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity))}
#__next :is(.text-amber-50) {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity))}
#__next :is(.text-amber-500) {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity))}
#__next :is(.text-amber-600) {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity))}
#__next :is(.text-amber-700) {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity))}
#__next :is(.text-amber-800) {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity))}
#__next :is(.text-amber-900) {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity))}
#__next :is(.text-black) {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))}
#__next :is(.text-blue-100) {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity))}
#__next :is(.text-blue-200) {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity))}
#__next :is(.text-blue-300) {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity))}
#__next :is(.text-blue-400) {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity))}
#__next :is(.text-blue-50) {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity))}
#__next :is(.text-blue-500) {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity))}
#__next :is(.text-blue-600) {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity))}
#__next :is(.text-blue-700) {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity))}
#__next :is(.text-blue-800) {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity))}
#__next :is(.text-blue-900) {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity))}
#__next :is(.text-cyan-100) {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity))}
#__next :is(.text-cyan-200) {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity))}
#__next :is(.text-cyan-300) {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity))}
#__next :is(.text-cyan-400) {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity))}
#__next :is(.text-cyan-50) {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity))}
#__next :is(.text-cyan-500) {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity))}
#__next :is(.text-cyan-600) {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity))}
#__next :is(.text-cyan-700) {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity))}
#__next :is(.text-cyan-800) {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity))}
#__next :is(.text-cyan-900) {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity))}
#__next :is(.text-emerald-100) {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity))}
#__next :is(.text-emerald-200) {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity))}
#__next :is(.text-emerald-300) {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity))}
#__next :is(.text-emerald-400) {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity))}
#__next :is(.text-emerald-50) {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity))}
#__next :is(.text-emerald-500) {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity))}
#__next :is(.text-emerald-600) {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity))}
#__next :is(.text-emerald-700) {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity))}
#__next :is(.text-emerald-800) {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity))}
#__next :is(.text-emerald-900) {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-100) {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-200) {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-300) {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-400) {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-50) {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-500) {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-600) {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-700) {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-800) {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity))}
#__next :is(.text-fuchsia-900) {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity))}
#__next :is(.text-gray-100) {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity))}
#__next :is(.text-gray-200) {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity))}
#__next :is(.text-gray-300) {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity))}
#__next :is(.text-gray-400) {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))}
#__next :is(.text-gray-50) {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity))}
#__next :is(.text-gray-500) {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))}
#__next :is(.text-gray-600) {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))}
#__next :is(.text-gray-700) {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))}
#__next :is(.text-gray-800) {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity))}
#__next :is(.text-gray-900) {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity))}
#__next :is(.text-green-100) {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity))}
#__next :is(.text-green-200) {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity))}
#__next :is(.text-green-300) {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity))}
#__next :is(.text-green-400) {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity))}
#__next :is(.text-green-50) {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity))}
#__next :is(.text-green-500) {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity))}
#__next :is(.text-green-600) {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity))}
#__next :is(.text-green-700) {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity))}
#__next :is(.text-green-800) {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity))}
#__next :is(.text-green-900) {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity))}
#__next :is(.text-indigo-100) {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity))}
#__next :is(.text-indigo-200) {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity))}
#__next :is(.text-indigo-300) {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity))}
#__next :is(.text-indigo-400) {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity))}
#__next :is(.text-indigo-50) {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity))}
#__next :is(.text-indigo-500) {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity))}
#__next :is(.text-indigo-600) {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity))}
#__next :is(.text-indigo-700) {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity))}
#__next :is(.text-indigo-800) {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity))}
#__next :is(.text-indigo-900) {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity))}
#__next :is(.text-inherit) {
    color: inherit}
#__next :is(.text-lime-100) {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity))}
#__next :is(.text-lime-200) {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity))}
#__next :is(.text-lime-300) {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity))}
#__next :is(.text-lime-400) {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity))}
#__next :is(.text-lime-50) {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity))}
#__next :is(.text-lime-500) {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity))}
#__next :is(.text-lime-600) {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity))}
#__next :is(.text-lime-700) {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity))}
#__next :is(.text-lime-800) {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity))}
#__next :is(.text-lime-900) {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity))}
#__next :is(.text-neutral-100) {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity))}
#__next :is(.text-neutral-200) {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity))}
#__next :is(.text-neutral-300) {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity))}
#__next :is(.text-neutral-400) {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity))}
#__next :is(.text-neutral-50) {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity))}
#__next :is(.text-neutral-500) {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity))}
#__next :is(.text-neutral-600) {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity))}
#__next :is(.text-neutral-700) {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity))}
#__next :is(.text-neutral-800) {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity))}
#__next :is(.text-neutral-900) {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity))}
#__next :is(.text-orange-100) {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity))}
#__next :is(.text-orange-200) {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity))}
#__next :is(.text-orange-300) {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity))}
#__next :is(.text-orange-400) {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity))}
#__next :is(.text-orange-50) {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity))}
#__next :is(.text-orange-500) {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity))}
#__next :is(.text-orange-600) {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity))}
#__next :is(.text-orange-700) {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity))}
#__next :is(.text-orange-800) {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity))}
#__next :is(.text-orange-900) {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity))}
#__next :is(.text-pink-100) {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity))}
#__next :is(.text-pink-200) {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity))}
#__next :is(.text-pink-300) {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity))}
#__next :is(.text-pink-400) {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity))}
#__next :is(.text-pink-50) {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity))}
#__next :is(.text-pink-500) {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity))}
#__next :is(.text-pink-600) {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity))}
#__next :is(.text-pink-700) {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity))}
#__next :is(.text-pink-900) {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity))}
#__next :is(.text-purple-100) {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity))}
#__next :is(.text-purple-200) {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity))}
#__next :is(.text-purple-300) {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity))}
#__next :is(.text-purple-400) {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity))}
#__next :is(.text-purple-50) {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity))}
#__next :is(.text-purple-500) {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity))}
#__next :is(.text-purple-600) {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity))}
#__next :is(.text-purple-700) {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity))}
#__next :is(.text-purple-800) {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity))}
#__next :is(.text-purple-900) {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity))}
#__next :is(.text-red-100) {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity))}
#__next :is(.text-red-200) {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity))}
#__next :is(.text-red-300) {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity))}
#__next :is(.text-red-400) {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity))}
#__next :is(.text-red-50) {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity))}
#__next :is(.text-red-500) {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity))}
#__next :is(.text-red-600) {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))}
#__next :is(.text-red-700) {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity))}
#__next :is(.text-red-800) {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity))}
#__next :is(.text-red-900) {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity))}
#__next :is(.text-rose-100) {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity))}
#__next :is(.text-rose-200) {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity))}
#__next :is(.text-rose-300) {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity))}
#__next :is(.text-rose-400) {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity))}
#__next :is(.text-rose-50) {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity))}
#__next :is(.text-rose-500) {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity))}
#__next :is(.text-rose-600) {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity))}
#__next :is(.text-rose-700) {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity))}
#__next :is(.text-rose-800) {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity))}
#__next :is(.text-rose-900) {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity))}
#__next :is(.text-sky-100) {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity))}
#__next :is(.text-sky-200) {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity))}
#__next :is(.text-sky-300) {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity))}
#__next :is(.text-sky-400) {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity))}
#__next :is(.text-sky-50) {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity))}
#__next :is(.text-sky-500) {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity))}
#__next :is(.text-sky-600) {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity))}
#__next :is(.text-sky-700) {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity))}
#__next :is(.text-sky-800) {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity))}
#__next :is(.text-sky-900) {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity))}
#__next :is(.text-slate-100) {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity))}
#__next :is(.text-slate-200) {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))}
#__next :is(.text-slate-300) {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity))}
#__next :is(.text-slate-400) {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))}
#__next :is(.text-slate-50) {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity))}
#__next :is(.text-slate-500) {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))}
#__next :is(.text-slate-600) {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))}
#__next :is(.text-slate-700) {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity))}
#__next :is(.text-slate-800) {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))}
#__next :is(.text-slate-900) {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity))}
#__next :is(.text-stone-100) {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity))}
#__next :is(.text-stone-200) {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity))}
#__next :is(.text-stone-300) {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity))}
#__next :is(.text-stone-400) {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity))}
#__next :is(.text-stone-50) {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity))}
#__next :is(.text-stone-500) {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity))}
#__next :is(.text-stone-600) {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity))}
#__next :is(.text-stone-700) {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity))}
#__next :is(.text-stone-800) {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity))}
#__next :is(.text-stone-900) {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity))}
#__next :is(.text-teal-100) {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity))}
#__next :is(.text-teal-200) {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity))}
#__next :is(.text-teal-300) {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity))}
#__next :is(.text-teal-400) {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity))}
#__next :is(.text-teal-50) {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity))}
#__next :is(.text-teal-500) {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity))}
#__next :is(.text-teal-600) {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity))}
#__next :is(.text-teal-700) {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity))}
#__next :is(.text-teal-800) {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity))}
#__next :is(.text-teal-900) {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity))}
#__next :is(.text-transparent) {
    color: transparent}
#__next :is(.text-violet-100) {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity))}
#__next :is(.text-violet-200) {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity))}
#__next :is(.text-violet-300) {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity))}
#__next :is(.text-violet-400) {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity))}
#__next :is(.text-violet-50) {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity))}
#__next :is(.text-violet-500) {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity))}
#__next :is(.text-violet-600) {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity))}
#__next :is(.text-violet-700) {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity))}
#__next :is(.text-violet-800) {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity))}
#__next :is(.text-violet-900) {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity))}
#__next :is(.text-white) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))}
#__next :is(.text-yellow-100) {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity))}
#__next :is(.text-yellow-200) {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity))}
#__next :is(.text-yellow-300) {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity))}
#__next :is(.text-yellow-400) {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity))}
#__next :is(.text-yellow-50) {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity))}
#__next :is(.text-yellow-500) {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity))}
#__next :is(.text-yellow-600) {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity))}
#__next :is(.text-yellow-700) {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity))}
#__next :is(.text-yellow-800) {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity))}
#__next :is(.text-yellow-900) {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity))}
#__next :is(.text-zinc-100) {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity))}
#__next :is(.text-zinc-200) {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity))}
#__next :is(.text-zinc-300) {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity))}
#__next :is(.text-zinc-400) {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity))}
#__next :is(.text-zinc-50) {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity))}
#__next :is(.text-zinc-500) {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity))}
#__next :is(.text-zinc-600) {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity))}
#__next :is(.text-zinc-700) {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity))}
#__next :is(.text-zinc-800) {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity))}
#__next :is(.text-zinc-900) {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity))}
#__next :is(.underline) {
    text-decoration-line: underline}
#__next :is(.line-through) {
    text-decoration-line: line-through}
#__next :is(.no-underline) {
    text-decoration-line: none}
#__next :is(.opacity-50) {
    opacity: 0.5}
#__next :is(.opacity-70) {
    opacity: 0.7}
#__next :is(.shadow) {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)}
#__next :is(.shadow-lg) {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)}
#__next :is(.shadow-none) {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)}
#__next :is(.shadow-sm) {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)}
#__next :is(.outline) {
    outline-style: solid}
#__next :is(.outline-amber-100) {
    outline-color: #fef3c7}
#__next :is(.outline-amber-200) {
    outline-color: #fde68a}
#__next :is(.outline-amber-300) {
    outline-color: #fcd34d}
#__next :is(.outline-amber-400) {
    outline-color: #fbbf24}
#__next :is(.outline-amber-50) {
    outline-color: #fffbeb}
#__next :is(.outline-amber-500) {
    outline-color: #f59e0b}
#__next :is(.outline-amber-600) {
    outline-color: #d97706}
#__next :is(.outline-amber-700) {
    outline-color: #b45309}
#__next :is(.outline-amber-800) {
    outline-color: #92400e}
#__next :is(.outline-amber-900) {
    outline-color: #78350f}
#__next :is(.outline-black) {
    outline-color: #000}
#__next :is(.outline-blue-100) {
    outline-color: #dbeafe}
#__next :is(.outline-blue-200) {
    outline-color: #bfdbfe}
#__next :is(.outline-blue-300) {
    outline-color: #93c5fd}
#__next :is(.outline-blue-400) {
    outline-color: #60a5fa}
#__next :is(.outline-blue-50) {
    outline-color: #eff6ff}
#__next :is(.outline-blue-500) {
    outline-color: #3b82f6}
#__next :is(.outline-blue-600) {
    outline-color: #2563eb}
#__next :is(.outline-blue-700) {
    outline-color: #1d4ed8}
#__next :is(.outline-blue-800) {
    outline-color: #1e40af}
#__next :is(.outline-blue-900) {
    outline-color: #1e3a8a}
#__next :is(.outline-cyan-100) {
    outline-color: #cffafe}
#__next :is(.outline-cyan-200) {
    outline-color: #a5f3fc}
#__next :is(.outline-cyan-300) {
    outline-color: #67e8f9}
#__next :is(.outline-cyan-400) {
    outline-color: #22d3ee}
#__next :is(.outline-cyan-50) {
    outline-color: #ecfeff}
#__next :is(.outline-cyan-500) {
    outline-color: #06b6d4}
#__next :is(.outline-cyan-600) {
    outline-color: #0891b2}
#__next :is(.outline-cyan-700) {
    outline-color: #0e7490}
#__next :is(.outline-cyan-800) {
    outline-color: #155e75}
#__next :is(.outline-cyan-900) {
    outline-color: #164e63}
#__next :is(.outline-emerald-100) {
    outline-color: #d1fae5}
#__next :is(.outline-emerald-200) {
    outline-color: #a7f3d0}
#__next :is(.outline-emerald-300) {
    outline-color: #6ee7b7}
#__next :is(.outline-emerald-400) {
    outline-color: #34d399}
#__next :is(.outline-emerald-50) {
    outline-color: #ecfdf5}
#__next :is(.outline-emerald-500) {
    outline-color: #10b981}
#__next :is(.outline-emerald-600) {
    outline-color: #059669}
#__next :is(.outline-emerald-700) {
    outline-color: #047857}
#__next :is(.outline-emerald-800) {
    outline-color: #065f46}
#__next :is(.outline-emerald-900) {
    outline-color: #064e3b}
#__next :is(.outline-fuchsia-100) {
    outline-color: #fae8ff}
#__next :is(.outline-fuchsia-200) {
    outline-color: #f5d0fe}
#__next :is(.outline-fuchsia-300) {
    outline-color: #f0abfc}
#__next :is(.outline-fuchsia-400) {
    outline-color: #e879f9}
#__next :is(.outline-fuchsia-50) {
    outline-color: #fdf4ff}
#__next :is(.outline-fuchsia-500) {
    outline-color: #d946ef}
#__next :is(.outline-fuchsia-600) {
    outline-color: #c026d3}
#__next :is(.outline-fuchsia-700) {
    outline-color: #a21caf}
#__next :is(.outline-fuchsia-800) {
    outline-color: #86198f}
#__next :is(.outline-fuchsia-900) {
    outline-color: #701a75}
#__next :is(.outline-gray-100) {
    outline-color: #f3f4f6}
#__next :is(.outline-gray-200) {
    outline-color: #e5e7eb}
#__next :is(.outline-gray-300) {
    outline-color: #d1d5db}
#__next :is(.outline-gray-400) {
    outline-color: #9ca3af}
#__next :is(.outline-gray-50) {
    outline-color: #f9fafb}
#__next :is(.outline-gray-500) {
    outline-color: #6b7280}
#__next :is(.outline-gray-600) {
    outline-color: #4b5563}
#__next :is(.outline-gray-700) {
    outline-color: #374151}
#__next :is(.outline-gray-800) {
    outline-color: #1f2937}
#__next :is(.outline-gray-900) {
    outline-color: #111827}
#__next :is(.outline-green-100) {
    outline-color: #dcfce7}
#__next :is(.outline-green-200) {
    outline-color: #bbf7d0}
#__next :is(.outline-green-300) {
    outline-color: #86efac}
#__next :is(.outline-green-400) {
    outline-color: #4ade80}
#__next :is(.outline-green-50) {
    outline-color: #f0fdf4}
#__next :is(.outline-green-500) {
    outline-color: #22c55e}
#__next :is(.outline-green-600) {
    outline-color: #16a34a}
#__next :is(.outline-green-700) {
    outline-color: #15803d}
#__next :is(.outline-green-800) {
    outline-color: #166534}
#__next :is(.outline-green-900) {
    outline-color: #14532d}
#__next :is(.outline-indigo-100) {
    outline-color: #e0e7ff}
#__next :is(.outline-indigo-200) {
    outline-color: #c7d2fe}
#__next :is(.outline-indigo-300) {
    outline-color: #a5b4fc}
#__next :is(.outline-indigo-400) {
    outline-color: #818cf8}
#__next :is(.outline-indigo-50) {
    outline-color: #eef2ff}
#__next :is(.outline-indigo-500) {
    outline-color: #6366f1}
#__next :is(.outline-indigo-600) {
    outline-color: #4f46e5}
#__next :is(.outline-indigo-700) {
    outline-color: #4338ca}
#__next :is(.outline-indigo-800) {
    outline-color: #3730a3}
#__next :is(.outline-indigo-900) {
    outline-color: #312e81}
#__next :is(.outline-lime-100) {
    outline-color: #ecfccb}
#__next :is(.outline-lime-200) {
    outline-color: #d9f99d}
#__next :is(.outline-lime-300) {
    outline-color: #bef264}
#__next :is(.outline-lime-400) {
    outline-color: #a3e635}
#__next :is(.outline-lime-50) {
    outline-color: #f7fee7}
#__next :is(.outline-lime-500) {
    outline-color: #84cc16}
#__next :is(.outline-lime-600) {
    outline-color: #65a30d}
#__next :is(.outline-lime-700) {
    outline-color: #4d7c0f}
#__next :is(.outline-lime-800) {
    outline-color: #3f6212}
#__next :is(.outline-lime-900) {
    outline-color: #365314}
#__next :is(.outline-neutral-100) {
    outline-color: #f5f5f5}
#__next :is(.outline-neutral-200) {
    outline-color: #e5e5e5}
#__next :is(.outline-neutral-300) {
    outline-color: #d4d4d4}
#__next :is(.outline-neutral-400) {
    outline-color: #a3a3a3}
#__next :is(.outline-neutral-50) {
    outline-color: #fafafa}
#__next :is(.outline-neutral-500) {
    outline-color: #737373}
#__next :is(.outline-neutral-600) {
    outline-color: #525252}
#__next :is(.outline-neutral-700) {
    outline-color: #404040}
#__next :is(.outline-neutral-800) {
    outline-color: #262626}
#__next :is(.outline-neutral-900) {
    outline-color: #171717}
#__next :is(.outline-orange-100) {
    outline-color: #ffedd5}
#__next :is(.outline-orange-200) {
    outline-color: #fed7aa}
#__next :is(.outline-orange-300) {
    outline-color: #fdba74}
#__next :is(.outline-orange-400) {
    outline-color: #fb923c}
#__next :is(.outline-orange-50) {
    outline-color: #fff7ed}
#__next :is(.outline-orange-500) {
    outline-color: #f97316}
#__next :is(.outline-orange-600) {
    outline-color: #ea580c}
#__next :is(.outline-orange-700) {
    outline-color: #c2410c}
#__next :is(.outline-orange-800) {
    outline-color: #9a3412}
#__next :is(.outline-orange-900) {
    outline-color: #7c2d12}
#__next :is(.outline-pink-100) {
    outline-color: #fce7f3}
#__next :is(.outline-pink-200) {
    outline-color: #fbcfe8}
#__next :is(.outline-pink-300) {
    outline-color: #f9a8d4}
#__next :is(.outline-pink-400) {
    outline-color: #f472b6}
#__next :is(.outline-pink-50) {
    outline-color: #fdf2f8}
#__next :is(.outline-pink-500) {
    outline-color: #ec4899}
#__next :is(.outline-pink-600) {
    outline-color: #db2777}
#__next :is(.outline-pink-700) {
    outline-color: #be185d}
#__next :is(.outline-pink-900) {
    outline-color: #831843}
#__next :is(.outline-purple-100) {
    outline-color: #f3e8ff}
#__next :is(.outline-purple-200) {
    outline-color: #e9d5ff}
#__next :is(.outline-purple-300) {
    outline-color: #d8b4fe}
#__next :is(.outline-purple-400) {
    outline-color: #c084fc}
#__next :is(.outline-purple-50) {
    outline-color: #faf5ff}
#__next :is(.outline-purple-500) {
    outline-color: #a855f7}
#__next :is(.outline-purple-600) {
    outline-color: #9333ea}
#__next :is(.outline-purple-700) {
    outline-color: #7e22ce}
#__next :is(.outline-purple-800) {
    outline-color: #6b21a8}
#__next :is(.outline-purple-900) {
    outline-color: #581c87}
#__next :is(.outline-red-100) {
    outline-color: #fee2e2}
#__next :is(.outline-red-200) {
    outline-color: #fecaca}
#__next :is(.outline-red-300) {
    outline-color: #fca5a5}
#__next :is(.outline-red-400) {
    outline-color: #f87171}
#__next :is(.outline-red-50) {
    outline-color: #fef2f2}
#__next :is(.outline-red-500) {
    outline-color: #ef4444}
#__next :is(.outline-red-600) {
    outline-color: #dc2626}
#__next :is(.outline-red-700) {
    outline-color: #b91c1c}
#__next :is(.outline-red-800) {
    outline-color: #991b1b}
#__next :is(.outline-red-900) {
    outline-color: #7f1d1d}
#__next :is(.outline-rose-100) {
    outline-color: #ffe4e6}
#__next :is(.outline-rose-200) {
    outline-color: #fecdd3}
#__next :is(.outline-rose-300) {
    outline-color: #fda4af}
#__next :is(.outline-rose-400) {
    outline-color: #fb7185}
#__next :is(.outline-rose-50) {
    outline-color: #fff1f2}
#__next :is(.outline-rose-500) {
    outline-color: #f43f5e}
#__next :is(.outline-rose-600) {
    outline-color: #e11d48}
#__next :is(.outline-rose-700) {
    outline-color: #be123c}
#__next :is(.outline-rose-800) {
    outline-color: #9f1239}
#__next :is(.outline-rose-900) {
    outline-color: #881337}
#__next :is(.outline-sky-100) {
    outline-color: #e0f2fe}
#__next :is(.outline-sky-200) {
    outline-color: #bae6fd}
#__next :is(.outline-sky-300) {
    outline-color: #7dd3fc}
#__next :is(.outline-sky-400) {
    outline-color: #38bdf8}
#__next :is(.outline-sky-50) {
    outline-color: #f0f9ff}
#__next :is(.outline-sky-500) {
    outline-color: #0ea5e9}
#__next :is(.outline-sky-600) {
    outline-color: #0284c7}
#__next :is(.outline-sky-700) {
    outline-color: #0369a1}
#__next :is(.outline-sky-800) {
    outline-color: #075985}
#__next :is(.outline-sky-900) {
    outline-color: #0c4a6e}
#__next :is(.outline-slate-100) {
    outline-color: #f1f5f9}
#__next :is(.outline-slate-200) {
    outline-color: #e2e8f0}
#__next :is(.outline-slate-300) {
    outline-color: #cbd5e1}
#__next :is(.outline-slate-400) {
    outline-color: #94a3b8}
#__next :is(.outline-slate-50) {
    outline-color: #f8fafc}
#__next :is(.outline-slate-500) {
    outline-color: #64748b}
#__next :is(.outline-slate-600) {
    outline-color: #475569}
#__next :is(.outline-slate-700) {
    outline-color: #334155}
#__next :is(.outline-slate-800) {
    outline-color: #1e293b}
#__next :is(.outline-slate-900) {
    outline-color: #0f172a}
#__next :is(.outline-stone-100) {
    outline-color: #f5f5f4}
#__next :is(.outline-stone-200) {
    outline-color: #e7e5e4}
#__next :is(.outline-stone-300) {
    outline-color: #d6d3d1}
#__next :is(.outline-stone-400) {
    outline-color: #a8a29e}
#__next :is(.outline-stone-50) {
    outline-color: #fafaf9}
#__next :is(.outline-stone-500) {
    outline-color: #78716c}
#__next :is(.outline-stone-600) {
    outline-color: #57534e}
#__next :is(.outline-stone-700) {
    outline-color: #44403c}
#__next :is(.outline-stone-800) {
    outline-color: #292524}
#__next :is(.outline-stone-900) {
    outline-color: #1c1917}
#__next :is(.outline-teal-100) {
    outline-color: #ccfbf1}
#__next :is(.outline-teal-200) {
    outline-color: #99f6e4}
#__next :is(.outline-teal-300) {
    outline-color: #5eead4}
#__next :is(.outline-teal-400) {
    outline-color: #2dd4bf}
#__next :is(.outline-teal-50) {
    outline-color: #f0fdfa}
#__next :is(.outline-teal-500) {
    outline-color: #14b8a6}
#__next :is(.outline-teal-600) {
    outline-color: #0d9488}
#__next :is(.outline-teal-700) {
    outline-color: #0f766e}
#__next :is(.outline-teal-800) {
    outline-color: #115e59}
#__next :is(.outline-teal-900) {
    outline-color: #134e4a}
#__next :is(.outline-transparent) {
    outline-color: transparent}
#__next :is(.outline-violet-100) {
    outline-color: #ede9fe}
#__next :is(.outline-violet-200) {
    outline-color: #ddd6fe}
#__next :is(.outline-violet-300) {
    outline-color: #c4b5fd}
#__next :is(.outline-violet-400) {
    outline-color: #a78bfa}
#__next :is(.outline-violet-50) {
    outline-color: #f5f3ff}
#__next :is(.outline-violet-500) {
    outline-color: #8b5cf6}
#__next :is(.outline-violet-600) {
    outline-color: #7c3aed}
#__next :is(.outline-violet-700) {
    outline-color: #6d28d9}
#__next :is(.outline-violet-800) {
    outline-color: #5b21b6}
#__next :is(.outline-violet-900) {
    outline-color: #4c1d95}
#__next :is(.outline-white) {
    outline-color: #fff}
#__next :is(.outline-yellow-100) {
    outline-color: #fef9c3}
#__next :is(.outline-yellow-200) {
    outline-color: #fef08a}
#__next :is(.outline-yellow-300) {
    outline-color: #fde047}
#__next :is(.outline-yellow-400) {
    outline-color: #facc15}
#__next :is(.outline-yellow-50) {
    outline-color: #fefce8}
#__next :is(.outline-yellow-500) {
    outline-color: #eab308}
#__next :is(.outline-yellow-600) {
    outline-color: #ca8a04}
#__next :is(.outline-yellow-700) {
    outline-color: #a16207}
#__next :is(.outline-yellow-800) {
    outline-color: #854d0e}
#__next :is(.outline-yellow-900) {
    outline-color: #713f12}
#__next :is(.outline-zinc-100) {
    outline-color: #f4f4f5}
#__next :is(.outline-zinc-200) {
    outline-color: #e4e4e7}
#__next :is(.outline-zinc-300) {
    outline-color: #d4d4d8}
#__next :is(.outline-zinc-400) {
    outline-color: #a1a1aa}
#__next :is(.outline-zinc-50) {
    outline-color: #fafafa}
#__next :is(.outline-zinc-500) {
    outline-color: #71717a}
#__next :is(.outline-zinc-600) {
    outline-color: #52525b}
#__next :is(.outline-zinc-700) {
    outline-color: #3f3f46}
#__next :is(.outline-zinc-800) {
    outline-color: #27272a}
#__next :is(.outline-zinc-900) {
    outline-color: #18181b}
#__next :is(.ring) {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)}
#__next :is(.ring-1) {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)}
#__next :is(.ring-2) {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)}
#__next :is(.ring-amber-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity))}
#__next :is(.ring-amber-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity))}
#__next :is(.ring-black) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity))}
#__next :is(.ring-blue-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity))}
#__next :is(.ring-cyan-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity))}
#__next :is(.ring-emerald-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity))}
#__next :is(.ring-fuchsia-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity))}
#__next :is(.ring-gray-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity))}
#__next :is(.ring-green-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity))}
#__next :is(.ring-green-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity))}
#__next :is(.ring-green-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity))}
#__next :is(.ring-green-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity))}
#__next :is(.ring-green-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity))}
#__next :is(.ring-green-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity))}
#__next :is(.ring-green-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity))}
#__next :is(.ring-green-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity))}
#__next :is(.ring-green-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity))}
#__next :is(.ring-green-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity))}
#__next :is(.ring-indigo-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity))}
#__next :is(.ring-lime-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity))}
#__next :is(.ring-neutral-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity))}
#__next :is(.ring-orange-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity))}
#__next :is(.ring-pink-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity))}
#__next :is(.ring-purple-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity))}
#__next :is(.ring-red-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity))}
#__next :is(.ring-red-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity))}
#__next :is(.ring-red-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity))}
#__next :is(.ring-red-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity))}
#__next :is(.ring-red-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity))}
#__next :is(.ring-red-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity))}
#__next :is(.ring-red-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity))}
#__next :is(.ring-red-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity))}
#__next :is(.ring-red-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity))}
#__next :is(.ring-red-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity))}
#__next :is(.ring-rose-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity))}
#__next :is(.ring-sky-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity))}
#__next :is(.ring-slate-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity))}
#__next :is(.ring-stone-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity))}
#__next :is(.ring-teal-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity))}
#__next :is(.ring-transparent) {
    --tw-ring-color: transparent}
#__next :is(.ring-violet-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity))}
#__next :is(.ring-violet-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity))}
#__next :is(.ring-white) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity))}
#__next :is(.ring-yellow-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-100) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-200) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-300) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-400) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-50) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-500) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-600) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-700) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-800) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity))}
#__next :is(.ring-zinc-900) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity))}
#__next :is(.blur) {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)}
#__next :is(.filter) {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)}
#__next :is(.transition) {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms}
#__next :is(.transition-all) {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms}

/* Disable tailwind outline on Tremor components */
.focus\:ring-blue-200:focus {
    --tw-ring-opacity: 0 !important
}
#__next :is(.placeholder\:text-gray-400)::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))}
#__next :is(.placeholder\:text-gray-400)::placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))}
#__next :is(.placeholder\:text-gray-500)::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))}
#__next :is(.placeholder\:text-gray-500)::placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))}
#__next :is(.visited\:text-blue-500:visited) {
    color: rgb(59 130 246 )}
#__next :is(.hover\:border-b-2:hover) {
    border-bottom-width: 2px}
#__next :is(.hover\:border-amber-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity))}
#__next :is(.hover\:border-amber-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity))}
#__next :is(.hover\:border-black:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity))}
#__next :is(.hover\:border-blue-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity))}
#__next :is(.hover\:border-cyan-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity))}
#__next :is(.hover\:border-emerald-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity))}
#__next :is(.hover\:border-fuchsia-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity))}
#__next :is(.hover\:border-gray-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity))}
#__next :is(.hover\:border-green-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity))}
#__next :is(.hover\:border-indigo-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity))}
#__next :is(.hover\:border-lime-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity))}
#__next :is(.hover\:border-neutral-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity))}
#__next :is(.hover\:border-orange-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity))}
#__next :is(.hover\:border-pink-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity))}
#__next :is(.hover\:border-purple-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity))}
#__next :is(.hover\:border-red-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity))}
#__next :is(.hover\:border-rose-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity))}
#__next :is(.hover\:border-sky-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity))}
#__next :is(.hover\:border-slate-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity))}
#__next :is(.hover\:border-stone-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity))}
#__next :is(.hover\:border-teal-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity))}
#__next :is(.hover\:border-transparent:hover) {
    border-color: transparent}
#__next :is(.hover\:border-violet-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity))}
#__next :is(.hover\:border-violet-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity))}
#__next :is(.hover\:border-white:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity))}
#__next :is(.hover\:border-yellow-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-100:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-200:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-300:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-400:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-50:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-600:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-700:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-800:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity))}
#__next :is(.hover\:border-zinc-900:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity))}
#__next :is(.hover\:bg-amber-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-amber-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-black:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-blue-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-cyan-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-emerald-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-fuchsia-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-gray-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-green-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-indigo-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-lime-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-neutral-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-orange-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-pink-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-purple-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-red-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-rose-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-sky-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-slate-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-stone-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-teal-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-transparent:hover) {
    background-color: transparent}
#__next :is(.hover\:bg-violet-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-violet-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-white:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-yellow-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-100:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-50:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-700:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity))}
#__next :is(.hover\:bg-zinc-900:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity))}
#__next :is(.hover\:text-amber-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity))}
#__next :is(.hover\:text-amber-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity))}
#__next :is(.hover\:text-black:hover) {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity))}
#__next :is(.hover\:text-blue-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity))}
#__next :is(.hover\:text-cyan-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity))}
#__next :is(.hover\:text-emerald-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity))}
#__next :is(.hover\:text-fuchsia-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity))}
#__next :is(.hover\:text-gray-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity))}
#__next :is(.hover\:text-green-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity))}
#__next :is(.hover\:text-indigo-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity))}
#__next :is(.hover\:text-lime-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity))}
#__next :is(.hover\:text-neutral-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity))}
#__next :is(.hover\:text-orange-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity))}
#__next :is(.hover\:text-pink-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity))}
#__next :is(.hover\:text-purple-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity))}
#__next :is(.hover\:text-red-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity))}
#__next :is(.hover\:text-rose-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity))}
#__next :is(.hover\:text-sky-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))}
#__next :is(.hover\:text-slate-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity))}
#__next :is(.hover\:text-stone-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity))}
#__next :is(.hover\:text-teal-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity))}
#__next :is(.hover\:text-transparent:hover) {
    color: transparent}
#__next :is(.hover\:text-violet-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity))}
#__next :is(.hover\:text-violet-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity))}
#__next :is(.hover\:text-white:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity))}
#__next :is(.hover\:text-yellow-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-100:hover) {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-200:hover) {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-300:hover) {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-50:hover) {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-800:hover) {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity))}
#__next :is(.hover\:text-zinc-900:hover) {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity))}
#__next :is(.hover\:underline:hover) {
    text-decoration-line: underline}
#__next :is(.hover\:ring-amber-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-amber-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-black:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-blue-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-cyan-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-emerald-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-fuchsia-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-gray-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-green-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-indigo-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-lime-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-neutral-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-orange-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-pink-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-purple-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-red-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-rose-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-sky-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-slate-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-stone-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-teal-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-transparent:hover) {
    --tw-ring-color: transparent}
#__next :is(.hover\:ring-violet-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-violet-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-white:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-yellow-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-100:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-200:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-300:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-400:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-50:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-500:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-600:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-700:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-800:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity))}
#__next :is(.hover\:ring-zinc-900:hover) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity))}
#__next :is(.focus\:outline-none:focus) {
    outline: 2px solid transparent;
    outline-offset: 2px}
#__next :is(.focus\:ring-0:focus) {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)}
#__next :is(.focus\:ring-2:focus) {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)}
#__next :is(.focus\:ring-amber-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-amber-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-black:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-blue-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-cyan-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-emerald-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-fuchsia-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-gray-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-green-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-indigo-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-lime-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-neutral-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-orange-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-pink-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-purple-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-red-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-rose-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-sky-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-slate-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-stone-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-teal-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-transparent:focus) {
    --tw-ring-color: transparent}
#__next :is(.focus\:ring-violet-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-violet-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-white:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-yellow-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-100:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-200:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-300:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-400:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-50:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-500:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-600:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-700:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-800:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-zinc-900:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity))}
#__next :is(.focus\:ring-offset-2:focus) {
    --tw-ring-offset-width: 2px}
@media (min-width: 640px) {
    #__next :is(.sm\:col-span-1) {
        grid-column: span 1 / span 1}
    #__next :is(.sm\:col-span-10) {
        grid-column: span 10 / span 10}
    #__next :is(.sm\:col-span-11) {
        grid-column: span 11 / span 11}
    #__next :is(.sm\:col-span-12) {
        grid-column: span 12 / span 12}
    #__next :is(.sm\:col-span-2) {
        grid-column: span 2 / span 2}
    #__next :is(.sm\:col-span-3) {
        grid-column: span 3 / span 3}
    #__next :is(.sm\:col-span-4) {
        grid-column: span 4 / span 4}
    #__next :is(.sm\:col-span-5) {
        grid-column: span 5 / span 5}
    #__next :is(.sm\:col-span-6) {
        grid-column: span 6 / span 6}
    #__next :is(.sm\:col-span-7) {
        grid-column: span 7 / span 7}
    #__next :is(.sm\:col-span-8) {
        grid-column: span 8 / span 8}
    #__next :is(.sm\:col-span-9) {
        grid-column: span 9 / span 9}
    #__next :is(.sm\:grid-cols-1) {
        grid-template-columns: repeat(1, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-10) {
        grid-template-columns: repeat(10, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-11) {
        grid-template-columns: repeat(11, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-12) {
        grid-template-columns: repeat(12, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-2) {
        grid-template-columns: repeat(2, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-3) {
        grid-template-columns: repeat(3, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-4) {
        grid-template-columns: repeat(4, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-5) {
        grid-template-columns: repeat(5, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-6) {
        grid-template-columns: repeat(6, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-7) {
        grid-template-columns: repeat(7, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-8) {
        grid-template-columns: repeat(8, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-9) {
        grid-template-columns: repeat(9, minmax(0, 1fr))}
    #__next :is(.sm\:grid-cols-none) {
        grid-template-columns: none}}
@media (min-width: 768px) {
    #__next :is(.md\:col-span-1) {
        grid-column: span 1 / span 1}
    #__next :is(.md\:col-span-10) {
        grid-column: span 10 / span 10}
    #__next :is(.md\:col-span-11) {
        grid-column: span 11 / span 11}
    #__next :is(.md\:col-span-12) {
        grid-column: span 12 / span 12}
    #__next :is(.md\:col-span-2) {
        grid-column: span 2 / span 2}
    #__next :is(.md\:col-span-3) {
        grid-column: span 3 / span 3}
    #__next :is(.md\:col-span-4) {
        grid-column: span 4 / span 4}
    #__next :is(.md\:col-span-5) {
        grid-column: span 5 / span 5}
    #__next :is(.md\:col-span-6) {
        grid-column: span 6 / span 6}
    #__next :is(.md\:col-span-7) {
        grid-column: span 7 / span 7}
    #__next :is(.md\:col-span-8) {
        grid-column: span 8 / span 8}
    #__next :is(.md\:col-span-9) {
        grid-column: span 9 / span 9}
    #__next :is(.md\:grid-cols-1) {
        grid-template-columns: repeat(1, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-10) {
        grid-template-columns: repeat(10, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-11) {
        grid-template-columns: repeat(11, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-12) {
        grid-template-columns: repeat(12, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-2) {
        grid-template-columns: repeat(2, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-3) {
        grid-template-columns: repeat(3, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-4) {
        grid-template-columns: repeat(4, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-5) {
        grid-template-columns: repeat(5, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-6) {
        grid-template-columns: repeat(6, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-7) {
        grid-template-columns: repeat(7, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-8) {
        grid-template-columns: repeat(8, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-9) {
        grid-template-columns: repeat(9, minmax(0, 1fr))}
    #__next :is(.md\:grid-cols-none) {
        grid-template-columns: none}}
@media (min-width: 1024px) {
    #__next :is(.lg\:col-span-1) {
        grid-column: span 1 / span 1}
    #__next :is(.lg\:col-span-10) {
        grid-column: span 10 / span 10}
    #__next :is(.lg\:col-span-11) {
        grid-column: span 11 / span 11}
    #__next :is(.lg\:col-span-12) {
        grid-column: span 12 / span 12}
    #__next :is(.lg\:col-span-2) {
        grid-column: span 2 / span 2}
    #__next :is(.lg\:col-span-3) {
        grid-column: span 3 / span 3}
    #__next :is(.lg\:col-span-4) {
        grid-column: span 4 / span 4}
    #__next :is(.lg\:col-span-5) {
        grid-column: span 5 / span 5}
    #__next :is(.lg\:col-span-6) {
        grid-column: span 6 / span 6}
    #__next :is(.lg\:col-span-7) {
        grid-column: span 7 / span 7}
    #__next :is(.lg\:col-span-8) {
        grid-column: span 8 / span 8}
    #__next :is(.lg\:col-span-9) {
        grid-column: span 9 / span 9}
    #__next :is(.lg\:grid-cols-1) {
        grid-template-columns: repeat(1, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-10) {
        grid-template-columns: repeat(10, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-11) {
        grid-template-columns: repeat(11, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-12) {
        grid-template-columns: repeat(12, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-2) {
        grid-template-columns: repeat(2, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-3) {
        grid-template-columns: repeat(3, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-4) {
        grid-template-columns: repeat(4, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-5) {
        grid-template-columns: repeat(5, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-6) {
        grid-template-columns: repeat(6, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-7) {
        grid-template-columns: repeat(7, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-8) {
        grid-template-columns: repeat(8, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-9) {
        grid-template-columns: repeat(9, minmax(0, 1fr))}
    #__next :is(.lg\:grid-cols-none) {
        grid-template-columns: none}}
.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
  }

.rdrDateDisplayItem input{
    text-align: inherit
  }

.rdrDateDisplayItem input:disabled{
      cursor: default;
    }

.rdrDateDisplayItemActive{}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers{
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrMonthPicker{}

.rdrYearPicker{}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrPprevButton {}

.rdrNextButton {}

.rdrMonths{
  display: flex;
}

.rdrMonthsVertical{
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.rdrMonth{
  width: 27.667em;
}

.rdrWeekDays{
  display: flex;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.rdrDateDisplayWrapper{}

.rdrMonthName{}

.rdrInfiniteMonths{
  overflow: auto;
}

.rdrDateRangeWrapper{
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
    outline: none;
  }

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
  }

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span{
    color: #1d2429;
  }

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.rdrInRange{}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.rdrDayHovered{}

.rdrDayActive{}

.rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDefinedRangesWrapper{}

.rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.rdrStaticRange{
  font-size: inherit;
}

.rdrStaticRangeLabel{}

.rdrInputRanges{}

.rdrInputRange{
  display: flex;
}

.rdrInputRangeInput{}

.rdrCalendarWrapper{
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper{
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
  }

.rdrDateDisplayItemActive{
  border-color: currentColor;
}

.rdrDateDisplayItemActive input{
    color: #7d888d
  }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
  }

.rdrMonthAndYearPickers select:hover{
      background-color: rgba(0,0,0,0.07);
    }

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7
}

.rdrNextPrevButton:hover{
    background: #E1E7F0;
  }

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
  }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429
}

.rdrDay:focus {
    outline: 0;
  }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
      background: #fff;
    }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
          color: rgba(255, 255, 255, 0.85);
        }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrInRange{}

.rdrStartEdge{
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge{
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 1.333em;
   border-bottom-right-radius: 1.333em;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
  }

.rdrStaticRange{
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
      background: #eff2f7;
    }

.rdrStaticRangeLabel{
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  padding: 10px 0;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput{
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
  }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
    display: none;
  }

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
  }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
  }

.rdrMonthName{
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

